import { Component, OnInit } from '@angular/core';
declare var $:any;
import * as moment from 'moment';
// interfaces
import { IHistory } from '../../shared/interfaces/app.interfaces';

// enums
import {
  Modules,
  StorageKeys,
} from '../../shared/enums/app.enums';

// services
import { StorageService } from 'src/app/shared/services/storage.service';

// interface History {
//   id: number,
//   start: Date,
//   end: Date,
//   target: number,
//   consumption: number,
//   result: number,
// }

@Component({
  selector: 'app-history-home',
  templateUrl: './history-home.component.html',
  styleUrls: ['./history-home.component.css']
})
export class HistoryHomeComponent implements OnInit {

  iconClasse = {
    success: "far fa-smile-beam",
    average: "far fa-grin-alt",
    turd: "fas fa-poop",
  }

  historyData: { [key: string]: IHistory[] } = {
    history: [],
  }

  constructor(
    private storage: StorageService,
  ) { }

  ngOnInit() {
    $(document).ready(function(){
      $('.collapsible').collapsible();
    }); 
    this.getHistory();
  }

  getHistory() {
    this.historyData = this.storage.getData(StorageKeys.HISTORY) || this.historyData;
  }

}
