import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
declare var M: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private push: SwPush,
  ) { 

  }

  byToast(msg: string) {
    let html = `
      <style>
        #toast-container {
          top: 0 !important;
          bottom: 0 !important;
          right: auto !important;
          max-height: 2rem;
        }
      </style>
      <div>${msg}</div>
    `;
    M.toast({html: html, classes: 'font-success'});
  }

  byPush() {
     // Check if the browser supports notifications
    if (!("Notification" in window)) {
      return;
    }

    // Check whether notification permissions have already been granted
    else if ((Notification as any).permission === "granted") {
      // If it's okay create a notification
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.showNotification("Hi there!");
      });
    }

    // Otherwise, ask the user for permission
    else if ((Notification as any).permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, create a notification
        if (permission === "granted") {
          navigator.serviceWorker.getRegistration().then((reg) => {
            reg.showNotification("Hi there!");
          });
        }
      });
    }

  }

}
