import {
  Component,
  OnInit,
  AfterViewInit,
} from '@angular/core';
declare let M: any;
declare let $: any;


// services
// import { StateService } from '../../shared/services/state.service';
import { StorageService } from '../../shared/services/storage.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SettingsService } from 'src/app/shared/services/settings.service';

// enums
import { Action } from '../../shared/enums/app-actions.enum';
import {
  Modules,
  StorageKeys,
  Features,
} from '../../shared/enums/app.enums';

// interfaces 
// import { GlobalAction } from '../../shared/interfaces/app-actions.interface';
import {
  Settings,
  SettingsV2,
  Config,
} from '../../shared/interfaces/app.interfaces';


@Component({
  selector: 'app-settings-home',
  templateUrl: './settings-home.component.html',
  styleUrls: ['./settings-home.component.css']
})
export class SettingsHomeComponent implements OnInit, AfterViewInit {

  year = new Date().getFullYear();

  quickAction: Config = {
    feature: Features.QUICKACTION,
    state: true,
    options: [
      Action.RESETLIST,
      Action.DISABLEQUICKACTION,
    ],
    selectedOption: Action.DISABLEQUICKACTION,
    default: Action.DISABLEQUICKACTION,
  }

  showCarb: Config = {
    feature: Features.SHOWCARB,
    state: true,
    options: [
      Action.SHOWCARB,
      Action.HIDECARB,
    ],
    selectedOption: Action.SHOWCARB,
    default: Action.SHOWCARB,
  };

  settings: Settings = {
    module: Modules.SETTINGS,
    storageKey: StorageKeys.SETTINGS,
    configs: {
      "QuickAction": this.quickAction,
      "ShowCarb": this.showCarb,
    },
  }

  defaultSettings: SettingsV2 = {
    features: {
      [Features.QUICKACTION]: { state: true },
      [Features.HEALTHSTATS]: { state: true },
      [Features.SHOWCARB]: { state: true },
    }
  }

  constructor(
    // private state: StateService,
    private storage: StorageService,
    private notify: NotificationService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    // this.monitorGlobalActions();
    // $('select').formSelect();
    this.loadSettings();

  }

  ngAfterViewInit() {
    var elems = document.querySelectorAll('select');
    var instances = M.FormSelect.init(elems);
  }

  loadSettings() {
    const temp = this.settingsService.getSettings(Modules.SETTINGS);
    if (temp){
      this.settings = temp;
      this.settings.configs.QuickAction.options = this.quickAction.options;
      if(!this.settings.configs.ShowCarb) {
        this.addNewSetting(Features.SHOWCARB, this.showCarb);
        this.loadSettings();
      }
      this.showCarb.state = this.settings.configs.ShowCarb.state;
    } else {
      this.updateNewSettings();
    }
    
  }

  updateNewSettings() {
    this.settings.configs = {
        ...this.settings.configs,
        "QuickAction": this.quickAction,
        "ShowCarb": this.showCarb,
      }
    this.settingsService.addSetting(Modules.SETTINGS, this.settings);
  }

  addNewSetting(name: Features, setting: Config) {
    this.settings.configs = {
        ...this.settings.configs,
        [name]: setting,
      }
    this.settingsService.addSetting(Modules.SETTINGS, this.settings);
  }

  showCarbUpdate() {
    this.settings.configs.ShowCarb.selectedOption = this.showCarb.state ? Action.SHOWCARB : Action.HIDECARB;
    this.settings.configs.ShowCarb.state = this.showCarb.state;
    this.saveSettings();
    this.settingsService.showCarb.next(this.showCarb.state);
  }

  getQuickActionOptions(option) {
    this.settings.configs.QuickAction.selectedOption = option.target.value;
    this.saveSettings();
    this.settingsService.quickAction.next(option.target.value as Action);
  }

  saveSettings() {
    this.settingsService.addSetting(Modules.SETTINGS, this.settings);
  }

  resetAll() {
    if(confirm("Are you sure you want to reset all?")) {
      this.storage.resetAll().subscribe(() => {
        this.notify.byToast("Reset all successful!");
      });
    }
  }

  resetList() {
    if(confirm("Are you sure you want to reset list?")) {
      this.storage.resetList().subscribe(() => {
        this.notify.byToast("Reset list successful!");
      });
    }
  }

  resetHistory() {
    if(confirm("Are you sure you want to reset history?")) {
      this.storage.deleteData(StorageKeys.HISTORY).subscribe(() => {
        this.notify.byToast("History reset successful!");
      });
    }
  }

  // resetTarget() {
  //   this.state.globalAction.next({ event: Action.RESETTARGET });
  // }

  // monitorGlobalActions() {
  //   this.state.globalAction.asObservable().subscribe((action) => {
  //     switch(action.event) {
  //       case Action.LISTRESETSUCCESS:
  //         $('#resetModal').modal('close');
  //         break;
  //       case Action.TARGETRESETSUCCESS:
  //         $('#resetModal').modal('close');
  //         break;
  //       case Action.RESETALLSUCCESS:
  //         $('#resetModal').modal('close');
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  // }

}
