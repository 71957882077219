import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.css']
})
export class BasicCardComponent implements OnInit {

  @Input() title: string | number = "Title";
  @Input() content: string | number;
  @Input() titleClass: string = "";
  @Input() contentClass: string = "white-text center medium-font";
  @Input() cardClass: string = "blue-grey darken-1";

  constructor() { }

  ngOnInit() {
  }

}
