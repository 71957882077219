export enum Action {
    RESETALL = "Reset All",
    DISABLEQUICKACTION = "Quick Action Disabled",
    RESETALLSUCCESS = "Reset All Success",
    RESETLIST = "Reset List",
    LISTRESETSUCCESS = "List Reset Success",
    RESETTARGET = "Reset Target",
    TARGETRESETSUCCESS = "Target Reset Success",
    OPENCALMODAL = "Open Cal Modal",
    OPENADDFOODMODAL = "Open Add Food Modal",
    SHOWCARB = "Show Carbohydrate",
    HIDECARB = "Hide Carbohydrate",

}
