export enum StorageKeys { 
    SETTINGS = "CalKicker.Settings",
    HISTORY = "CalKicker.History",
    HEALTHSTATS = "CalKicker.HealthStats",
}

export enum Modules {
    GLOBAL = "Global",
    HOME = "Home",
    SETTINGS = "Settings",
    HISTORY = "History",
}

export enum Features {
    QUICKACTION = "QuickAction",
    SHOWCARB = "ShowCarb",
    HEALTHSTATS = "HealthStats",
}