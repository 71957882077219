import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
declare var M: any;
declare var $: any;

// import * as $ from 'jquery';

import { Subscription } from 'rxjs';

// services
import { StorageService } from '../../shared/services/storage.service';
import { NotificationService } from '../../shared/services/notification.service';
import { StateService } from '../../shared/services/state.service';
import { Action } from 'src/app/shared/enums/app-actions.enum';
import { SettingsService } from 'src/app/shared/services/settings.service';

// interfaces 
import {
  Modules,
  StorageKeys,
  Features,
} from "../../shared/enums/app.enums";
import { IHealthStats } from 'src/app/shared/interfaces/app.interfaces';
interface Food {
  name: string;
  calories: number;
  carbs?: number;
}

interface FoodStorage {
  target: number | string,
  remains: number,
  foods: Food[],
}

const foodPlaceholders = [
  "Yummy",
  "Delish",
  "Good stuff",
  "Satisfactory",
  "Meh",
  "Correction",
];

const targetExceedComments = [
  "You have exceeded your target! Proud of yourself? Hmm...",
  "You are passed your target, slow it down brah, slow it down...",
  "Target exceeded! You shall not pass!!!",
  "No judging, but you have surpassed your target... and being judged... not lightly.",
  "Target passed! Teach yourself this and you would have taught yourself grandly: Self Control!",
]

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit, OnDestroy, OnChanges {

  target: number;
  curFoodName: string;
  curFoodCal: number;
  curFoodCarbs: number;
  remains: number;
  
  weight: number;
  heightFeet: number;
  heightInches: number;
  height: number;
  bmi: number;
  weightCategory: string;

  healthStats: IHealthStats;

  showCarb = true;
  showHealthStats = true;
  appUpdateAvailable: boolean;

  data: FoodStorage = {
    target: null,
    remains: null,
    foods: [],
  }

  remainsClass = {
    'center': false,
    'large-font': true,
    'font-success': true,
    'font-caution': false,
    'font-danger': false,
  };

  defaultFood: Food = {
    name: "",
    calories: 0,
    carbs: 0,
  }

  private stateSub: Subscription;
  private updateSub: Subscription;

  constructor(
    private storage: StorageService,
    private notify: NotificationService,
    private state: StateService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    // let elems = document.querySelectorAll('.modal');
    // let instances = M.Modal.init(elems);
    
    $(document).ready(function(){
      $('.modal').modal();
      $('.tabs').tabs();
    });

    this.data = this.storage.loadData() || this.data;
    this.remains = this.data.remains;
    this.monitorGlobalActions();
    this.updateRemainsClass();
    this.showCarb = this.setShowCarb();
    this.loadHealthStats();
    this.updateSub = this.settingsService._appUpdateAvailable$.subscribe((updateAvaiable) => {
      this.appUpdateAvailable = updateAvaiable;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    this.stateSub.unsubscribe();
    this.updateSub.unsubscribe();
  }

  setShowCarb() {
    if (this.settingsService.settings.get(Modules.SETTINGS) && this.settingsService.settings.get(Modules.SETTINGS).configs.ShowCarb) {
      return this.settingsService.settings.get(Modules.SETTINGS).configs.ShowCarb.state;
    } else { 
      return false;
    }
  }

  notifyMe() {
    this.notify.byPush();
  }

  reloadApp() {
    document.location.reload();
  }

  openCalModal() {
    $('#modal1').modal('open');
  }

  openAddFoodModal() {
    $('#modal2').modal('open');
  }

  openHealthStatsModal() {
    $('#healthStatsModal').modal('open');
  }

  openResetListModal() {
    $('#resetListModal').modal('open');
  }

  saveHealthStats() {
    this.height = this.heightFeet && this.heightInches ? (this.heightFeet * 12) + this.heightInches : (this.heightFeet * 12) || this.heightInches;
    this.bmi = this.height && this.weight ? Number(((this.weight / Math.pow(this.height, 2)) * 703).toFixed(2)) : null;
    this.weightCategory = this.bmi ? this.setWeightCategory(this.bmi) : null;
    this.healthStats = {
      weight: this.weight,
      height: this.height,
      heightFeet: this.heightFeet,
      heightInches: this.heightInches,
      bmi: this.bmi,
      category: this.weightCategory,
    }
    this.storage.storeData(StorageKeys.HEALTHSTATS, this.healthStats);
  }

  setWeightCategory(bmi: number): string {
    if (bmi < 18.5) return "Underweight";
    if (bmi >= 18.5 && bmi < 25) return "Normal";
    if (bmi >= 25 && bmi < 30) return "Overweight";
    return "Obese";
  }

  loadHealthStats() {
    const healthStats: IHealthStats = this.storage.getData(StorageKeys.HEALTHSTATS);
    if (healthStats) {
      this.weight = healthStats.weight;
      this.height = healthStats.height;
      this.heightFeet = healthStats.heightFeet;
      this.heightInches = healthStats.heightInches;
      this.bmi = healthStats.bmi;
      this.weightCategory = healthStats.category;
    }
  }

  saveTarget() {
    if (this.target > 5000) {
      this.notify.byToast("Come on man... that's too much!");
      return;
    }
    if (this.target < 1) { this.notify.byToast("Minimum of 1 please..."); return }
    if (this.target == null) return;
    this.data.target = this.target;
    this.target = null;
    this.storage.saveData(this.data);
    this.updateRemainsClass();
  }

  addFood() {
    if (!this.curFoodCal || this.curFoodCal < 0 || !this.data.target) {
      if (!this.data.target) { this.notify.byToast("Please enter a target first!"); return }
      if (!this.curFoodCal) { this.notify.byToast("Calorie count is required!"); return }
      if (this.curFoodCal < 0) { this.notify.byToast(`Subtracted ${Math.abs(this.curFoodCal)} from total so far!`); }
    };
    
    const select = this.curFoodCal < 0 ? foodPlaceholders.length - 1 : this.randomNum(foodPlaceholders.length);
    const temp: Food = {
      name: this.curFoodName || foodPlaceholders[select],
      calories: this.curFoodCal,
      carbs: this.curFoodCarbs,
    }; 

    this.data.foods.push(temp);
    this.data.remains = this.calcRemains();
    if (this.data.remains > this.data.target && !(this.curFoodCal < 0)) { 
      this.notify.byToast(targetExceedComments[this.randomNum(targetExceedComments.length + 1)]);
    }
    this.saveData();
    this.updateRemainsClass();
    this.curFoodName = null;
    this.curFoodCal = null;
    this.curFoodCarbs = null;

  }

  calcRemains() {
   return this.data.foods.reduce((acc: number, curr: Food) => {
      return acc + curr.calories;
    }, 0);
  }

  updateRemainsClass() {
    const acc = (this.data.remains / Number(this.data.target)) * 100;
    this.remainsClass["font-success"] = acc < 75;
    this.remainsClass["font-caution"] = acc >= 75 && acc < 100;
    this.remainsClass["font-danger"] = acc >= 100; 
  }

  resetList() {
      this.storage.resetList().subscribe(() => {
        this.remains = null;
        this.data.remains = null;
        this.data.foods = [];
        this.saveData();
        this.updateRemainsClass();
         this.state.globalAction.next({ event: Action.LISTRESETSUCCESS });
        this.notify.byToast("Reset list successful!");
      });
  }

  resetTarget() {
    if (!confirm("Are you sure you want to reset target?")) {
      return;
    }

    this.data.target = null;
    this.target = null;
    this.saveData();
    this.updateRemainsClass();
    this.state.globalAction.next({ event: Action.TARGETRESETSUCCESS });
  }

  resetDay() {

    if (!confirm("Are you sure you want reset all?")) {
      return;
    }

    this.remains = null;
    this.data.remains = null;
    this.data.foods = [];
    this.data.target = null;
    this.target = null;
    this.storage.eraseData();
    this.updateRemainsClass();
    this.state.globalAction.next({ event: Action.RESETALLSUCCESS });
  }

  saveData() {
    this.storage.saveData(this.data);
  }

  randomNum(max: number) {
    return Math.floor(Math.random() * (max - 1));
  }

  monitorGlobalActions() {
    this.stateSub = this.state.globalAction.subscribe((action) => {
      switch(action.event) {
        case Action.RESETALL:
          this.resetDay();
          break;
        case Action.RESETLIST:
          this.openResetListModal();
          break;
        case Action.RESETTARGET:
          this.resetTarget();
          break;
        case Action.OPENADDFOODMODAL:
          this.openAddFoodModal();
          break;
        default:
          break;
      }
    });
  }

}
