import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { NavComponent } from './shared/components/nav/nav.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ActionBarComponent } from './shared/components/action-bar/action-bar.component';

import { HomeModule } from "./home/home.module";
import { CalorieTrackerModule } from "./calorie-tracker/calorie-tracker.module";
import { SettingsModule } from "./settings/settings.module";
import { HistoryModule } from "./history/history.module";
import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    PageNotFoundComponent,
    ActionBarComponent,
  ],
  imports: [
    BrowserModule,
    HomeModule,
    CalorieTrackerModule,
    SettingsModule,
    HistoryModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
