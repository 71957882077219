import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SettingsService } from './shared/services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cal Kicker';

  constructor(
    updates: SwUpdate,
    settingsService: SettingsService,
    ) {
    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => {
        settingsService.appUpdateAvailable.next(true);
      });
    });
  }
}
