import { Injectable } from '@angular/core';
import * as moment from 'moment';

// RxJS
import { Observable, of } from 'rxjs';

// Enums
import { StorageKeys } from '../enums/app.enums';
import { Settings, IHistory } from '../interfaces/app.interfaces';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  saveKey = "cal-control";

  constructor() { }

  public resetList(): Observable<boolean> {
    let data = this.loadData();
    this.saveHistory(data);
    data.foods = [];
    data.remains = 0;
    this.saveData(data);
    return of(true);
  }

  public resetTarget(): Observable<boolean> {
    let data = this.loadData();
    data.target = 0;
    this.saveData(data);
    return of(true);
  }

  public saveHistory(data) {
    let historyData: { [key: string]: IHistory[] } = this.getData(StorageKeys.HISTORY);
    const historyPoint = {
        id: (new Date()).getTime(),
        end: moment().format('MMMM Do YYYY, h:mm:ss a'),
        foods: data.foods,
        target: data.target,
        consumption: data.remains,
        result: (data.remains/data.target) * 100,
    };

    if (historyData) {
        historyData.history.push(historyPoint);
    } else {
      historyData = {
        history: [historyPoint],
      };
    }
    this.storeData(StorageKeys.HISTORY, historyData);
  }

  public resetAll(): Observable<boolean> {
    this.eraseData();
    return of(true);
  }

  // DEPRECATED
  public saveData(data: Object) {
    localStorage.setItem(this.saveKey, JSON.stringify(data));
  }

  public storeData(storageKey: StorageKeys, data: Object) {
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  public storeSettings(storageKey: StorageKeys, data: Map<string, Settings>) {
    localStorage.setItem(storageKey, this.strMapToJson(data));
  }

  // DEPRECATED
  public loadData() {
    return JSON.parse(localStorage.getItem(this.saveKey));
  }

  public getData(storageKey: StorageKeys) {
    return JSON.parse(localStorage.getItem(storageKey));
  }

  public deleteData(storageKey: StorageKeys) {
    localStorage.removeItem(storageKey);
    return of(true);
  }

  // DEPRECATED
  public eraseData() {
    localStorage.removeItem(this.saveKey);
  }

  strMapToObj(strMap: Map<string, Settings>) {
    
    let obj = Object.create(null);
    strMap.forEach((val, key) => {
      obj[key] = val;
    });
    return obj;
  }

  strMapToJson(strMap) {
    return JSON.stringify(this.strMapToObj(strMap));
  }

}
