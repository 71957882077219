import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// enums
import { Action } from '../enums/app-actions.enum';

// interfaces
import { GlobalAction } from '../interfaces/app-actions.interface';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  globalAction = new Subject<GlobalAction>();

  constructor() { }
}
