import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CalorieTrackerRoutingModule } from './calorie-tracker-routing.module';
import { LandingComponent } from './landing/landing.component';
import { BasicCardComponent } from '../shared/components/basic-card/basic-card.component';

import { NotificationService } from '../shared/services/notification.service';

@NgModule({
  imports: [
    CommonModule,
    CalorieTrackerRoutingModule,
    FormsModule,
  ],
  declarations: [
    LandingComponent,
    BasicCardComponent,
  ],
  providers: [
    NotificationService,
  ]
})
export class CalorieTrackerModule { }
