import {
  Component,
  OnInit,
} from '@angular/core';

import { Router } from '@angular/router';

// services 
import { StateService } from '../../services/state.service';
import { SettingsService } from '../../services/settings.service';

// enums
import { Action } from '../../enums/app-actions.enum';
import { Modules } from '../../enums/app.enums';
import { Settings } from '../../interfaces/app.interfaces';

declare var $:any;

@Component({
  selector: 'action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.css']
})
export class ActionBarComponent implements OnInit {

  quickAction: Action;
  quickSetting: Settings;

  constructor(
    private router: Router,
    private state: StateService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.getQuickAction();
    $(document).ready(function(){
      $('.modal').modal();
    });
  }

  getQuickAction() {
    this.quickSetting = this.settingsService.getSettings(Modules.SETTINGS);
    if (this.quickSetting) {
      this.quickAction = this.quickSetting.configs.QuickAction.selectedOption as Action;
    }
    this.settingsService.quickAction.subscribe((action) => {
      this.quickAction = action;
    });
  }

  launchQuickAction() {
    if (this.quickAction && this.quickAction != Action.DISABLEQUICKACTION) {
      this.router.navigate(["calorie-tracker"])
      .then((done) => {
        if (done) {
          setTimeout(() => {
            this.state.globalAction.next({ event: this.quickAction });
          }, 500);
        } else {
          this.state.globalAction.next({ event: this.quickAction });
        }
      });
    } else {
      $('#quickActionModal').modal('open');
    }
  }

  openCalModal() {
    this.router.navigate(["calorie-tracker"])
      .then((done) => {
        if (done) {
          setTimeout(() => {
            this.state.globalAction.next({ event: Action.OPENADDFOODMODAL });
          }, 500);
        } else {
          this.state.globalAction.next({ event: Action.OPENADDFOODMODAL });
        }
      });
  }

  routeTo(link: string) {
    this.router.navigate([link]);
  }

}
