import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var M: any;
declare var $: any;

// services
import { StateService } from '../../services/state.service';

// enums
import { Action } from '../../enums/app-actions.enum';

// interfaces 
import { GlobalAction } from '../../interfaces/app-actions.interface';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(
    private state: StateService,
    private router: Router,
  ) { }

  ngOnInit() {
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.sidenav');
      var instances = M.Sidenav.init(elems);
    });
    $(".dropdown-trigger").dropdown();
    this.monitorGlobalActions();
  }

  resetDay() {
    this.state.globalAction.next({ event: Action.RESETALL });
  }

  resetList() {
    this.state.globalAction.next({ event: Action.RESETLIST });
  }

  resetTarget() {
    this.state.globalAction.next({ event: Action.RESETTARGET });
  }

  monitorGlobalActions() {
    this.state.globalAction.asObservable().subscribe((action) => {
      switch(action.event) {
        case Action.LISTRESETSUCCESS:
          $('#resetModal').modal('close');
          break;
        case Action.TARGETRESETSUCCESS:
          $('#resetModal').modal('close');
          break;
        case Action.RESETALLSUCCESS:
          $('#resetModal').modal('close');
          break;
        default:
          break;
      }
    });
  }

  routeTo(link: string) {
    this.router.navigate([link]);
  }

}
