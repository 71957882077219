import { Injectable } from '@angular/core';

// RxJS
import { Subject } from "rxjs";

// interfaces
import {
  Settings,
} from '../interfaces/app.interfaces';

// enums
import {
  Modules,
  StorageKeys,
} from '../enums/app.enums';
import { Action } from '../enums/app-actions.enum';

// services
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: Map<string, Settings> = new Map();
  quickAction: Subject<Action> = new Subject();
  showCarb: Subject<Boolean> = new Subject();
  appUpdateAvailable: Subject<boolean> = new Subject();
  _appUpdateAvailable$ = this.appUpdateAvailable.asObservable();

  constructor(
    private storage: StorageService,
  ) {
    this.loadSettings();
  }

  getSettings(module: Modules): Settings {
    return this.settings.get(module);
  }

  addSetting(module: Modules, settings: Settings) {
    this.settings.set(module, settings);
    this.saveSettings();
  }

  private loadSettings() {
   this.settings = new Map(Object.entries(this.storage.getData(StorageKeys.SETTINGS) || {}));
  }

  private saveSettings() {
    this.storage.storeSettings(StorageKeys.SETTINGS, this.settings);
  }

}
